


import { Component, Prop, Vue } from "vue-property-decorator";

import { globalUpdateDetailService } from "@/Services/GlobalService";
import { CrudType } from "@/Services/enums";
import { IStrDetailValue, IStrValue } from "@/Services/Interfaces";

export interface IBaseSubComponent {
  showAction(field: IStrDetailValue): boolean;
  canEdit(field: IStrDetailValue): boolean;
  canEditMemo(field: IStrDetailValue): boolean;
  canShow(field: IStrDetailValue): boolean;
  canUpdate(field: IStrDetailValue): boolean;
  canShow(field: IStrDetailValue): boolean;
  canShowLabel(field: IStrDetailValue): boolean;
}

@Component
export default class BaseSubComponent extends Vue implements IBaseSubComponent {

  constructor() {
    super();
    this.name = "Component";
    this.canAction = false;
  }

  @Prop()
  modus: string | undefined;

  // siehe Date in den eeinzelkomponenten
  @Prop()
  model: IStrDetailValue | undefined ;

  name: string;
  canAction: boolean;

  public loadActionImage(): any {
    try {
      return require("../../assets/gifs/advance.png");
    } catch (e) {
      return "";
    }
  }

  getLabelText(model: IStrDetailValue): string {

    return model ?  model.fieldname : "";

  }

  public canShowLabel(field: IStrDetailValue): boolean {
    //  console.debug( "canshow  " +  field.visibility +  "modus"  +  this.modus);
    if (field == undefined) return false;
    if (field.visibility == undefined) return true;

    if ( field.visibility.toLowerCase().indexOf("hiddenlabel") > -1 &&   field.crud.toLowerCase() === "noedit"    )      return false;
    

    return true;
  }

  /**Kann angezeigt werden */
  public canShow(field: IStrDetailValue): boolean {
    if (field == undefined) return false;
    if (field.visibility == undefined) return true;

    if (field.visibility.toLowerCase() === "hidden") return false;

    if (
      field.visibility.toLowerCase().indexOf("hidden") > -1 &&
      field.visibility.toLowerCase().indexOf(field.crud.toLowerCase()) > -1
    )
      return false;

    return true;
  }

  // @Prop()
  //models: IStrDetailValue[];

  public canUpdate(field: IStrDetailValue): boolean {
    if (field == undefined || field.crud == undefined) {
      return false;
    }
    return field.crud == CrudType.update ||
      field.crud == CrudType.updatestrvalue ||
      field.crud == CrudType.updatememovalue
      ? true
      : false;
  }

  public isNew(field: IStrDetailValue): boolean {
    if (field == undefined || field.crud == undefined) return false;
    return field.crud === CrudType.create;
  }

  showAction(field: IStrDetailValue): boolean {
    if (field == undefined || field.crud == undefined) return false;
    if (field.valuetyp.toLowerCase() == "relation") return false;
    if (field.crud == CrudType.create) return false;
    return this.canEdit(field);
  }

 canShowMemo(field: IStrDetailValue): boolean {


    if ( field.memovalue && field.memovalue.length > 0 &&  field.memovalue != field.strvalue ) return true;

    return false;
  }

 canEditMemo(field: IStrDetailValue): boolean {

    if ( field.memovalue && field.memovalue.length == 0 || ( field.memovalue && field.memovalue.length > 0 &&  field.memovalue == field.strvalue )) return false;
    if (field.typname.toLowerCase() == 'whatsup')  return false;
    return this.canEdit(field);
  }


  public canEdit(field: IStrDetailValue): boolean {

    if (field == undefined) return false;
    if (field.crud == undefined) return false;

    return field.crud == CrudType.update ||
      field.crud == CrudType.updatestrvalue ||
      field.crud == CrudType.create ||
      field.crud == CrudType.updatememovalue
      ? true
      : false;
  }

  computed() {}

  mounted()
  {
      this.$emit("childRefs", this.$refs);
  }
}
