











































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue } from "@/Services/Interfaces";

import { InfoType, CrudType } from "@/Services/enums";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import ActionComponent from "./ActionComponent.vue";
import BaseSubComponent from "./BaseSubComponent.vue";

@Component({ components: { ActionComponent, BaseSubComponent } })
export default class CheckBoxComponent extends BaseSubComponent {
  constructor() {
    super();
    this.name = "CheckBoxComponent";
    this.thismodel = <IStrDetailValue>{};
    this.checked = false;

  }

  checked : boolean;

  getList(field: IStrDetailValue): string[] {
    if (field.valuetyp.toLowerCase() == "checkbox") {

    }

    return field.listvalues.split(";");
  }




  thismodel: IStrDetailValue;


 onCheckboxChange(newValue : any, oldValue: any)
 {
    this.onUpdate(this.thismodel, (newValue ? "-1" : "0") );
 }


  onUpdate(model: IStrDetailValue, option: string) {
    if (this.canUpdate(model)) {
      this.thismodel.strvalue = option;
      this.thismodel.crud = CrudType.updatestrvalue;
      globalUpdateDetailService.sendMessage(this.thismodel);
    }
  }

  data() {
    return {
      thismodel: this.model,
      canAction: false,
      checked :  ( this.model != undefined && this.model.strvalue === "-1" ? true : false) ,
    };
  }

  computed() {}
}
